.container {
  max-width: 1300px;
  cursor: default;
  margin-top: 5px;
}

@media (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
  }
  .container {
    max-width: 768px;
    cursor: default;
    text-align: center;
  }
  .navbar-brand {
    margin-right: 0rem;
  }
}
