/* default */

.completed-border {
  border-top: 8px solid #2d7373;
  margin-top: 8px;
}

.completed-border-fail {
  border-top: 8px solid #909f9f;
  margin-top: 8px;
}

.incomplete-border {
  border-top: 8px solid #f37145;
  margin-top: 8px;
}

.current-border {
  border: 1px solid #2d7373;
  border-top: 8px solid #2d7373;
  margin-top: 0;
}

.test-thumb {
  background-image: url('https://storage.googleapis.com/bukalemun-bucket/images/test-thumb.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95%;
  height: 200px;
  margin-top: -40px;
  margin-bottom: -4px;
}

.sum-test-thumb {
  background-image: url('https://storage.googleapis.com/bukalemun-bucket/images/sum-test-thmb.png');
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
  height: 200px;
  margin-top: -40px;
  margin-bottom: -4px;
}

.word-paper {
  width: 255px;
  height: 200px;
}

.word-thumb {
  background-color: #a0a0a0;
  width: 253px;
  height: 150px;
}

.play-triangle {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 35px solid #a0a0a0;
  border-bottom: 20px solid transparent;
  margin-left: 17px;
  border-radius: 3px;
}
.play-circle {
  width: 60px;
  height: 60px;
  background: #6a6a6a;
  border-radius: 50%;
}
