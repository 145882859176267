body {
  overflow-x: hidden;
}

#root {
  overflow: hidden;
}

.h-70 {
  height: 70px;
}

.flex-rect {
  width: 100%;
}
.flex-rect::before {
  content: '';
  display: block;
  padding-top: 20px;
  margin-top: 17px;
}

.footer-top {
  padding-top: 25px;
  padding-bottom: 45px;
}

.footer-bottom {
  position: absolute;
  padding: 8px 0px;
  width: 100%;
}

.paper {
  border-radius: 3px;
  box-shadow: 0 3px 9px 0 rgba(21, 27, 38, 0.1);
  border: solid 1px #d4d7dd;
  background-color: #ffffff;
  text-align: center;
}

.paper-lg {
  border-radius: 3px;
  box-shadow: 0 3px 9px 0 rgba(21, 27, 38, 0.1);
  border: solid 1px #d4d7dd;
  background-color: #ffffff;
  text-align: center;
  margin-top: 70px;
}

.paper-lg-rnd {
  border: solid 1px #707070;
  border-radius: 50%;
  background-color: #ffffff;
}

.circle-25 {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}

.circle-125 {
  width: 125px;
  height: 125px;
  border-radius: 100%;
}

/*          colors          */
.op-30 {
  opacity: 30%;
}

.back-lt-green {
  background-color: #e9f1f1;
}

.back-green {
  background-color: #2d7373;
}

.back-lg-blue {
  background-color: #e8f0f0;
}

.back-orng {
  background-color: #f27244;
}

.back-green-white {
  background: linear-gradient(180deg, #e8f0f0 85%, #ffffff 15%);
}

/*          borders          */
.no-border {
  border: none;
}

.border-1-green {
  border: solid 1px #2d7373;
}

.border-tb1-gray {
  border-top: solid 1px #d4d7dd;
  border-bottom: solid 1px #d4d7dd;
}

.border-tb1-green {
  border-top: solid 1px #2d7373;
  border-bottom: solid 1px #2d7373;
}

.border-tb1-drk-orng {
  border-top: solid 1px #f37145;
  border-bottom: solid 1px #f37145;
}

.border-b1-orng {
  border-bottom: solid 1px #f37145;
}

.border-b1-gray {
  border-bottom: solid 1px #d4d7dd;
}

.border-green {
  border-color: #2d7373;
}

.no-border-rad {
  border-radius: 0;
}

/* medium devices */
@media only screen and (min-width: 767px) {
  .back-green-white {
    background: linear-gradient(180deg, #e8f0f0 70%, #ffffff 30%);
  }
}
