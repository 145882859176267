/* default */
.navlink {
  margin: 0px 15px;
  padding: 4px 0px;
}
a.navlink.btn.btn-padded.txt-md.nav-link {
  color: initial;
}
a.navlink.btn.txt-md.btn-green.btn-sm.nav-link,
a.navlink.btn.txt-md.btn-green.btn-sm.nav-link:hover,
.navbar-light .navbar-nav a.nav-link,
.navbar-light .navbar-nav a.nav-link:hover {
  color: #ffffff;
}
nav.navbar-nav {
  padding: 0;
}
.navbar-light button.navbar-toggler {
  border: none;
}
.navbar-light button.navbar-toggler:focus {
  outline: unset;
}

a.carousel-control-prev {
  left: -125px;
}
a.carousel-control-next {
  right: -125px;
}

div.carousel.slide {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d4d7dd;
}
