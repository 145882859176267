/* default */
.cursor-pntr {
  cursor: pointer;
}

input[type='checkbox'] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  height: 20px;
  width: 20px;
}

input[type='checkbox']:checked {
  background: #ffffff;
}

input[type='checkbox']:hover {
  filter: brightness(90%);
}

input[type='checkbox']:disabled {
  background: #e6e6e6;
  opacity: 0;
  pointer-events: none;
}

input[type='checkbox']:after {
  content: '';
  position: relative;
  left: 40%;
  top: 20%;
  width: 30%;
  height: 55%;
  border: solid #2d7373;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type='checkbox']:checked:after {
  display: block;
}

input[type='checkbox']:disabled:after {
  border-color: #7b7b7b;
}
