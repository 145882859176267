/* default */
.MuiFormLabel-root {
  text-align: left;
}

.MuiFormControl-root {
  width: 100%;
}

li.MuiAutocomplete-option {
  font-family: Poppins;
  font-size: 14px;
  padding: 5px;
}

li.MuiAutocomplete-option svg.MuiSvgIcon-root {
  fill: #a0a0a0;
  padding: 2px;
}

span.MuiButton-label {
  font-size: 14px;
  font-family: Poppins;
  color: #161211;
}
div.MuiPickersYear-root {
  font-family: Poppins;
}
div.MuiPickersYear-root:focus {
  color: #161211;
}

div.MuiPickersYear-yearSelected {
  font-family: Poppins;
  color: #2d7373;
}
div.MuiPickersBasePicker-pickerView {
  font-family: Poppins;
}
button.MuiPickersDay-daySelected {
  background-color: #2d7373;
}

div.MuiFormGroup-root {
  width: fit-content;
}
span.MuiRadio-colorSecondary.Mui-checked {
  color: #2d7373;
}
div.MuiFormGroup-root label.MuiFormControlLabel-root {
  margin-bottom: 20px;
}

div.MuiFormControl-root span.MuiButtonBase-root:hover,
button.MuiButtonBase-root.MuiIconButton-root:hover,
label.MuiFormControlLabel-root span.MuiIconButton-root:hover {
  background-color: unset;
  opacity: 0.7;
  transition: all 0.1s ease-in-out;
}

button.MuiButtonBase-root:focus {
  outline: none;
}

input.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: Poppins;
  font-size: 14px;
  padding: 17px 20px;
}

span.MuiChip-label {
  font-family: Poppins;
  font-size: 12px;
  white-space: pre-wrap;
}

div.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
  margin-right: 30px;
  height: auto;
}

div.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 0px;
}

div span.MuiButtonBase-root.MuiIconButton-root {
  margin-left: 3px;
  padding: unset;
  align-self: end;
  padding-left: 9px;
  padding-right: 9px;
}

div.MuiAutocomplete-endAdornment {
  margin-right: 4px;
}

div.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
  padding: 7.5px;
}

div.MuiPaper-elevation1 {
  border-radius: 3px;
  box-shadow: 0 3px 9px 0 rgba(21, 27, 38, 0.1);
  border: solid 1px #d4d7dd;
  background-color: #ffffff;
}
div.MuiButtonBase-root.MuiExpansionPanelSummary-root {
  flex-direction: row-reverse;
  margin: -5px 0px;
  padding-left: 5px;
}
div.MuiButtonBase-root.MuiExpansionPanelSummary-root:hover {
  opacity: 0.7;
  transition: all 0.1s ease-in-out;
}
div.MuiExpansionPanelSummary-expandIcon {
  transform: rotate(0deg);
}
div.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg);
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: unset;
}

#Beni-hatirla
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  color: #2d7373;
}

#Beni-hatirla {
  text-align: center;
  margin-left: 7px;
}

#Date-picker div div div.MuiInputAdornment-positionEnd {
  margin-right: -13px;
}

/* medium devices */
@media only screen and (min-width: 767px) {
  #Beni-hatirla {
    text-align: left;
    margin-left: 0px;
  }
}
