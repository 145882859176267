[class*='txt-'] {
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #161211;
  white-space: pre-wrap;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.txt-underlined {
  text-decoration: underline;
}

/**          size          **/
.txt-xs {
  font-size: 10px;
}

.txt-xs-2 {
  font-size: 12px;
}

.txt-sm {
  font-size: 14px;
}

.txt-md {
  font-size: 16px;
}

.txt-lg {
  font-size: 20px;
}

.txt-lg-2 {
  font-size: 24px;
}

.txt-xl {
  font-size: 30px;
}

.txt-xxl {
  font-size: 36px;
}

.txt-3xl {
  font-size: 45px;
}

.txt-5xl {
  font-size: 85px;
}

/**          weight          **/
.txt-bold {
  font-weight: 700;
}

.txt-bold-mx {
  font-weight: 900;
}

.txt-lt {
  font-weight: 300;
}

/**          colors          **/
.txt-green {
  color: #2d7373;
}

.txt-drk-green {
  color: #1a3540;
}

.txt-lt-green {
  color: #bfd5d5;
}

.txt-orng {
  color: #f27244;
}

.txt-drk-orng {
  color: #f37145;
}

.txt-gray {
  color: #909f9f;
}

.txt-lt-gray {
  color: #a0a0a0;
}

.txt-white {
  color: #ffffff;
}

/**          alignment          **/
.txt-ctr {
  text-align: center;
}

.txt-r {
  text-align: center;
}

.txt-l {
  text-align: center;
}

.txt-no-wrap {
  white-space: nowrap;
}

.txt-wrap {
  white-space: pre-wrap;
}

.word-brk {
  word-break: break-all;
}

.letter-sp-7 {
  letter-spacing: 7px;
}

.letter-sp-n2 {
  letter-spacing: -2px;
}

/**          special          **/
.txt-in-circle {
  width: 90px;
  height: 90px;
  background-color: #f3694c;
  margin: -45px auto 0px;
  z-index: 2;
  border-radius: 50%;
}

.border-btm-sld {
  border-bottom-style: solid;
}

.border-btm-dsh {
  border-bottom-style: dotted;
  padding-bottom: 6px;
}

/* medium devices */
@media only screen and (min-width: 767px) {
  .txt-r {
    text-align: right;
  }

  .txt-l {
    text-align: left;
  }
}
