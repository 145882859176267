/* default */
[type='button'] {
  -webkit-appearance: button-bevel;
}

[class*='btn-'],
.btn {
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

[class*='btn-']:focus,
.btn:focus {
  box-shadow: unset;
  outline: unset;
}

[class*='btn-']:hover,
.btn:hover {
  text-decoration: none;
  opacity: 0.7;
}

[class*='btn-']:hover:disabled {
  cursor: not-allowed;
  opacity: 1;
}

.btn {
  font-family: Poppins;
  line-height: 1.5;
  text-align: center;
  color: #161211;
  text-decoration: none;
}

.btn-logo {
  width: 259px;
  height: 59px;
  object-fit: contain;
}

.btn-underlined,
.btn-underlined:hover {
  text-decoration: underline;
}

.btn-bold {
  font-weight: 600;
}

.btn-green {
  background-color: #2d7373;
  border: none;
  color: #ffffff;
}
.btn-green:disabled {
  border: solid 1px #2d7373;
  background-color: #bfd5d5;
  color: #2d7373;
}

.btn-lt-green {
  border: solid 1px #a0a0a0;
  background-color: #e8f0f0;
  color: #2d7373;
}

.btn-orange {
  background-color: #f27244;
  color: #ffffff;
}

.btn-gray {
  border: solid 1px #707070;
  background-color: #e4e4e4;
  color: #ffffff;
}

.btn-dark-green {
  background-color: #1a3540;
  color: #ffffff;
}

.btn-green:hover,
.btn-orange:hover,
.btn-gray:hover,
.btn-dark-green:hover,
.btn-logo:hover {
  opacity: 0.9;
  color: #ffffff;
}

.btn-xs {
  width: 85px;
  height: 35px;
  border-radius: 7px;
}

.btn-sm {
  width: 135px;
  height: 35px;
  border-radius: 7px;
}

.btn-md-sh {
  width: 180px;
  height: 45px;
  border-radius: 7px;
}

.btn-md {
  width: 225px;
  height: 45px;
  border-radius: 7px;
}

.btn-lg {
  width: 375px;
  height: 40px;
  border-radius: 7px;
}

.btn-full {
  width: 100%;
  height: 50px;
  border-radius: 7px;
}

.btn-sm-round {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  margin: 2px 0px;
}

.btn-md-round {
  width: 53px;
  height: 53px;
  border-radius: 100%;
}

.btn-lg-round {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 8px 8px;
}

/*          special           */
.btn-full-2 {
  width: 100%;
  height: 55px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(21, 27, 38, 0.16);
  border: solid 1px #a0a0a0;
  background-image: url('https://storage.googleapis.com/bukalemun-bucket/images/study-btn.png');
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: contain;
}
.btn-full-2-ch {
  width: 100%;
  height: 55px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(21, 27, 38, 0.16);
  border: solid 1px #f27244;
  background-image: url('https://storage.googleapis.com/bukalemun-bucket/images/study-btn.png');
  background-position: calc(10%);
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.btn-full-2-ch::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://storage.googleapis.com/bukalemun-bucket/images/tick.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -10px;
}
