.z-2 {
  z-index: 2;
}

.w-100 {
  width: 100%;
}

/** alignments **/
.pos-abs {
  position: absolute;
}

.center-hr {
  margin: auto;
}

.justify-ctr {
  justify-content: center;
}

.justify-even {
  justify-content: space-evenly;
}

.justify-btwn {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.inl-flex {
  display: inline-flex;
}

.no-wrap {
  flex-wrap: nowrap;
}
.wrap-rev {
  flex-wrap: wrap-reverse;
}

.align-base {
  vertical-align: baseline;
}

.align-vsub {
  vertical-align: sub;
}

.align-ttop {
  vertical-align: text-top;
}

.align-ctr {
  align-items: center;
}

.align-items-bl {
  align-items: baseline;
}

.align-r {
  text-align: right;
}

.align-l {
  text-align: left;
}

/*          margins          */
.m-15 {
  margin: 15px;
}

.m-30 {
  margin: 30px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-n6 {
  margin-left: -6px;
}

.ml-n10 {
  margin-left: -10px;
}

.ml-n15 {
  margin-left: -15px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-n10 {
  margin-right: -10px;
}

.mr-n25 {
  margin-right: -25px;
}

.mr-n45 {
  margin-right: -45px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-25 {
  margin-right: 25px;
}

.mt-inh {
  margin-top: inherit;
}

.mt-n6 {
  margin-top: -6px;
}

.mt-n10 {
  margin-top: -10px;
}

.mt-n35 {
  margin-top: -35px;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-1n {
  margin-bottom: -1px;
}

.mb-n15 {
  margin-bottom: -15px;
}

.mb-n30 {
  margin-bottom: -30px;
}

.mb-n60 {
  margin-bottom: -60px;
}

.mb-n150 {
  margin-bottom: -150px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-120 {
  margin-bottom: 120px;
}

/*          paddings          */
.no-pad {
  padding: unset;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-l1 {
  padding-left: 1px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-25 {
  padding-right: 25px;
}

.p-t1 {
  padding-top: 1px;
}

.p-t5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.p-b5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-150 {
  padding-bottom: 150px;
}

/***          special          ***/
#Footer-row,
#Footer-div {
  text-align: center;
  margin: 15px 0 5px;
}

#Gizle {
  padding-top: 8px;
  padding-right: 10px;
}

#Watch-sidepanel {
  justify-content: center;
}

#Watch-bottom {
  justify-content: center;
}

@media only screen and (min-width: 767px) {
  #Footer-row {
    text-align: right;
    margin-top: 0;
    margin-bottom: 15px;
  }

  #Footer-div {
    text-align: right;
    margin: 50px 0 -10px;
  }

  #Gizle {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  #Watch-sidepanel {
    justify-content: flex-end;
  }

  #Watch-bottom {
    justify-content: space-between;
  }
}
