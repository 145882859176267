/* default */
.react-multi-carousel-item:hover {
  opacity: 0.8;
  transition: all 0.15s ease-in-out;
}

.react-multiple-carousel__arrow {
  background-color: #ffffff;
  border: solid 1px #2d7373;
  transition: all 0.5s ease-in-out;
}
.react-multiple-carousel__arrow:hover {
  background-color: #ffffff;
  border-radius: 3px;
}
.react-multiple-carousel__arrow::before {
  color: #2d7373;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:focus,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:focus {
  outline: none;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  margin-left: -53px;
  padding-right: 11px;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  margin-right: -53px;
  padding-left: 11px;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before {
  padding-left: 2px;
}
#Bottom-carousel
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  margin-left: -34px;
}
#Bottom-carousel
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  margin-right: -34px;
}
